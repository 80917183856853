@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/animate.css/animate.min.css";

@import "./ColourPalette.scss";

h1 {
    font-family: "Montserrat", sans-serif;
    &.lead {
        font-weight: 400;
        line-height: 1.5;
        font-size: 1.34rem;
        margin: auto;
        height: fit-content;
        @media (min-width: 768px) {
            font-size: 2.6rem;
        }
        @media (min-width: 1200px) {
            font-size: 3.2rem;
        }
        > em {
            font-family: inherit;
            font-size: inherit;
        }
        > strong {
            font-family: inherit;
            font-size: inherit;
            transition: color 250ms linear;
            position: relative;
            cursor: pointer;
            &::after {
                content: "";
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 0%;
                height: 2px;
                background-color: $primary-color;
                transition: width 0.2s ease-in-out;
            }
            &:hover {
                color: $primary-color;
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

header {
    button {
        i {
            font-size: 2.2rem;
            @media (min-width: 768px) {
                font-size: 3rem;
            }
        }
    }
}
