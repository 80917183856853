@import "./ColourPalette.scss";

.btn {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    border-radius: 2rem;
}

.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;

    small {
        text-transform: none;
        font-weight: 400;
    }
}
