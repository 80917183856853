.alert {
    max-width: 520px;
    .alert-heading {
        font-size: 1rem;
    }
    p,
    * {
        font-size: 0.88rem;
    }
    button {
        font-size: 0.88rem;
    }
}

.modal {
    .modal-header,
    .modal-body,
    .modal-footer {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }
}
