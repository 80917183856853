.embed-video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}

.embed-responsive {
    > iframe {
        width: 100%;
        &.video {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
        }
        &.maps {
            height: 300px;
            vertical-align: bottom !important;
            margin-bottom: -1px;
        }
        &.form {
            height: 600px;
            overflow: hidden !important;
        }
    }
}
