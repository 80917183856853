#icon-github {
    &:hover {
        color: #6e5494 !important;
    }
}

#icon-linkedin {
    &:hover {
        color: #0a66c2 !important;
    }
}

#icon-code-square {
    &:hover {
        color: #ccc !important;
    }
}

#icon-medium {
    &:hover {
        color: #00ab6c !important;
    }
}

#icon-stack-overflow {
    &:hover {
        color: #f48024 !important;
    }
}

#icon-box {
    &:hover {
        color: #0ebeff !important;
    }
}

#icon-behance {
    &:hover {
        color: #1769ff !important;
    }
}

#icon-twitter {
    &:hover {
        color: #1da1f2 !important;
    }
}

#icon-facebook {
    &:hover {
        color: #1877f2 !important;
    }
}

#icon-instagram {
    &:hover {
        color: #c13584 !important;
    }
}

#icon-reddit {
    &:hover {
        color: #ff4500 !important;
    }
}

#icon-youtube {
    &:hover {
        color: #ff0000 !important;
    }
}

#icon-vimeo {
    &:hover {
        color: #1ab7ea !important;
    }
}

#icon-apple {
    color: white;
    text-decoration-color: white;
    &:hover {
        > i {
            color: #fd5c63 !important;
        }
    }
}

#icon-music-note-beamed {
    color: white;
    text-decoration-color: white;
    &:hover {
        > i {
            color: #d51007 !important;
        }
    }
}

.icon-link-hover {
    > i {
        font-size: 100%;
        &::before {
            vertical-align: super;
        }
    }
}
