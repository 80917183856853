@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/animate.css/animate.min.css";

@import "./ColourPalette.scss";
@import "./EmbedResponsive.scss";
@import "./Backgrounds.scss";
@import "./Buttons.scss";
@import "./Navigation.scss";
@import "./Header.scss";
@import "./Logo.scss";
@import "./Icons.scss";
@import "./MessageBoxes.scss";

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Pompiere&display=swap");

*,
html,
body {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    cursor: default;
    > small {
        display: block;
        font-size: 60%;
        font-weight: 700;
    }
}

h2 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 3rem;
}

h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

header {
    button {
        i {
            font-size: 4rem;
        }
    }
}

small {
    font-size: 60%;
    font-weight: 400;
    a {
        font-size: inherit;
    }

    @media (min-width: 768px) {
        font-size: 70%;
        font-weight: 100;
    }
}

abbr {
    cursor: help !important;
}

a {
    color: $primary-color;
    text-underline-offset: 0.25em;
    &:link,
    &:visited {
        color: $primary-color;
    }
    &:hover,
    &:focus,
    &:active {
        opacity: 0.75;
    }
}
