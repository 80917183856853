@import "./ColourPalette.scss";

h5.logo {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    color: $logo-color;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    small {
        font-family: "Pompiere", sans-serif;
        font-weight: 400;
        font-style: normal;
        color: $primary-color;
        display: block;
        font-size: 39.2%;
        margin-top: -8px;
    }
}

.navbar-brand {
    pad &:hover {
        opacity: 1 !important;
    }
}

footer {
    h5.logo {
        font-size: 2.6rem;
    }
}
