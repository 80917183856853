@import "./ColourPalette.scss";

nav {
    backdrop-filter: saturate(180%) blur(20px);
    padding: 0.4rem 0rem 0.4rem 0rem !important;
    @media (min-width: 768px) {
        padding: 1.2rem 3rem 1.2rem 3rem !important;
    }
    .navbar-nav {
        display: flex;
        align-items: flex-end;
    }
    a.nav-link {
        font-size: 0.86rem;
        font-weight: 500;
        position: relative;
        margin-left: 1rem;
        transition: color 0.2s ease-in;
        &.active {
            text-decoration: underline;
        }
        &.btn {
            &.active {
                text-decoration: none;
            }
        }
    }
    &.bg-white {
        background-color: rgba(255, 255, 255, 0.8) !important;
        a.nav-link {
            &:link,
            &:visited {
                color: black;
            }
            &:hover {
                opacity: 1;
                color: $primary-color;
            }
            &.btn-dark {
                background-color: black;
                border: 1px solid black;
                color: white;
                text-transform: none;
                transition: background-color 0.3s ease-in;
                &:hover {
                    background-color: transparent;
                    color: black;
                    &::after {
                        width: 0;
                    }
                }
            }
        }
    }

    &.bg-black {
        background-color: rgba(0, 0, 0, 0.8) !important;
        a.nav-link {
            &:link,
            &:visited {
                color: white;
            }
            &:hover {
                opacity: 1;
                color: $primary-color;
            }
            &.btn-light {
                background-color: white;
                border: 1px solid white;
                color: black;
                text-transform: none;
                transition: background-color 0.3s ease-in;
                &:hover {
                    background-color: transparent;
                    color: white;
                }
            }
        }
    }

    button.navbar-toggler {
        background-color: black;
        border-color: black;
        border-radius: 100%;
        padding: 4px 2px 0 2px;
        transition: background-color 0.5s ease;
        &.collapsed {
            background-color: white;
            border-color: white;
            span.navbar-toggler-icon {
                &::after {
                    content: "⋯";
                    color: black;
                    font-size: 1.4rem;
                }
            }
        }
        span.navbar-toggler-icon {
            background-image: none;
            &::after {
                content: "✕";
                color: white;
                font-size: 1.4rem;
            }
        }
    }
}
