.bg-black {
    color: white;
    a {
        color: white;
        text-underline-offset: 0.25em;
        text-decoration-color: white;
        &:link,
        &:visited {
            color: white;
        }
        &:hover,
        &:focus,
        &:active {
            opacity: 0.75;
            text-decoration-color: inherit;
        }
    }

    .btn-primary {
        background-color: white;
        border-color: white;
        color: black !important;
        &:focus,
        &:active {
            background-color: transparent !important;
            border-color: white;
            color: white !important;
        }
    }
}
