@import "./ColourPalette.scss";

.loader-spinner {
    .spinner-grow-sm {
        width: 0.68rem;
        height: 0.68rem;
    }
    span {
        font-size: 0.68rem;
        vertical-align: text-bottom;
    }
}

.progress {
    height: 12px;
    width: 100%;
    position: absolute;
    > .progress-bar {
        background-color: $primary-color;
        font-size: 0.54rem;
        text-transform: uppercase;
    }
}
