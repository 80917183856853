@import "./ColourPalette.scss";

a.icon-link-hover {
    --bs-icon-link-transform: translate3d(0, -0.125rem, 0);
}

.accordion-button {
    &:focus {
        box-shadow: none;
    }
    &:not(.collapsed) {
        background-color: black;
        color: white;
        &::after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>");
            color: white;
        }
        &:focus {
            box-shadow: none;
        }
    }
}

.accordion-body {
    a:link,
    a:visited {
        color: black;
        text-decoration-color: black;
    }
    a:hover {
        opacity: 1;
        color: $primary-color;
        text-decoration-color: $primary-color;
    }
}
